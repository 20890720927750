import React from 'react';

import './Redeem.css';

const Redeem = () => {
  return (
    <div className="container">
      <h1>Redeem</h1>
      <p>
        9 trillion can be redeem on bounce finance. After the initial redeem
        process is finished you will be able to sell your tokens on polygon or
        stake them to gain all the new juicy inflation from money printing.
      </p>
      <br></br>
      <a href="https://app.bounce.finance/auction/fixed-price/19102">
        {' '}
        <h1>Click here to redeem on BOUNCE FINANCE </h1>
      </a>
    </div>
  );
};

export default Redeem;
