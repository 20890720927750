import React, { useState, useEffect } from 'react';
import './Stake.css';
import Web3 from 'web3';

const web3 = new Web3(Web3.givenProvider);
const stakingContractAddress = '0x4a9C121080f6D9250Fc0143f41B595fD172E31bf';
const stakingContractABI = []; // Fill this with your Staking contract ABI

const stakingContract = new web3.eth.Contract(
  stakingContractABI,
  stakingContractAddress
);

function StakingPage() {
  const [account, setAccount] = useState('');
  const [amount, setAmount] = useState(0);
  const [stakedTokens, setStakedTokens] = useState(0);
  const [pendingRewards, setPendingRewards] = useState(0);

  const loadStakingData = async (account) => {
    const tokens = await stakingContract.methods.stakedTokens(account).call();
    const rewards = await stakingContract.methods
      .calculateReward(account)
      .call();
    setStakedTokens(web3.utils.fromWei(tokens, 'ether'));
    setPendingRewards(web3.utils.fromWei(rewards, 'ether'));
  };

  useEffect(() => {
    async function loadBlockchainData() {
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);
      loadStakingData(accounts[0]);
    }
    loadBlockchainData();
  }, []);

  useEffect(() => {
    if (account) {
      loadStakingData(account);
    }
  }, [account]);

  const handleStake = async () => {
    const amountInWei = web3.utils.toWei(amount.toString(), 'ether');
    await stakingContract.methods.stake(amountInWei).send({ from: account });
  };

  return (
    <div>
      <div className="comingsoon">
        <h1>ETA: Before XMAS</h1>
        <p>
          {' '}
          All the new inflation gets spread out between the stakers from the
          stake pool.{' '}
        </p>
      </div>

      <div className="staking-page">
        <h1>Staking</h1>
        <div className="staking-form">
          <h2>Stake your tokens</h2>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Amount to stake"
          />
          <button disabled onClick={handleStake}>
            Stake
          </button>
        </div>
        <div className="user-info">
          <h2>Your Staking Information</h2>
          <p>Staked Tokens: {stakedTokens}</p>
          <p>Pending Rewards: {pendingRewards}</p>
        </div>
      </div>
    </div>
  );
}

export default StakingPage;
