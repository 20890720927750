import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faTelegram,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';

import './Footer.css'; // This is assuming you have a CSS file for styles.

function redirectToExternalPage(url) {
  window.open(url, '_blank');
  // <button className='telegram' onClick={() => redirectToExternalPage('https://t.me/+8Vkjig23sQw2ZmZi')}> TELEGRAM </button>
}

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <div className="social-icons">
        <a
          href="https://x.com/thegarygensler"
          className="social-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a
          href="https://t.me/+8Vkjig23sQw2ZmZi"
          className="social-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTelegram} size="2x" />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
