import React from 'react';

import './Gamble.css';

const Gamble = () => {
  return (
    <div className="container">
      <h1> ETA: Before XMAS</h1>
      <br></br>
      <h1>Gamble</h1>
      <p>
        <b>Coming soon</b>, you will be able to flip a coin, heads or tails,
        code will be run on blockchain. If you win you double your coins by
        taking it out of gambling pool, if you lose your money gets deposited to
        gambling pool. The chances will be 50/50. Will accept only GGC.{' '}
      </p>
    </div>
  );
};

export default Gamble;
