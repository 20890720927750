import React from 'react';

import './About.css';
import meme from './meme.webp';



const About = () => {
    

  return (
    <div className="container">
      <h1 style={{"marginBottom": "0px"}}>About</h1>
      <div className="explanation">
        <div className="expbox">
        <span className="highlighted2">The US Dollar</span>, despite its dominance in the global market, is facing challenges due to its dependence on an increasingly outdated financial system. This began with the establishment of <span className="highlighted">central banks</span> after World War I and was further exacerbated by the end of the <span className="highlighted">gold standard </span>in 1971, which allowed a select group of central bankers to control the money system. The 2008 financial crisis revealed the fragility of this system, as unprecedented <span className="highlighted">quantitative easing</span> measures were introduced to stabilize the economy.
        Unfortunately, these actions often benefited the wealthy at the expense of ordinary citizens, leading to growing wealth inequality and reduced purchasing power.
        <span className="highlighted2"> Political leaders</span> have been known to make lofty promises to voters, which can result in <span className="highlighted">deficit spending</span> and mounting national debt. This borrowed currency often finds its way into the pockets of corporations, corrupt politicians, or as bailouts for irresponsible banks who gamble with other peoples money. Meanwhile, <span className="highlighted2">ordinary citizens</span> are burdened with repaying this debt through various taxes, some completely ridiculous such as property tax (You don't own property if you have to pay rent to goverment).
        </div>

        <img src={meme} alt="meme" className="memeimg" style={{width:'20em', margin:'1em auto'}}/>

        <div className="expbox">
        In contrast, cryptocurrencies like <span className="highlighted2">Bitcoin</span> offer a more equitable and efficient system for preserving purchasing power and ensuring a fair distribution of resources. <span className="highlighted2">Gary Gensler Coin (GGc)</span> is a meme crypto designed to critique figures like <span className="highlighted">Gary Gensler</span> and the shortcomings of the traditional financial system and also be potential inflation hedge. The supply of GGc will be tied to the US National debt, and the inflation will be redistributed among all GGc stakers. Inflation averages at 65,000 per second and is rewarded to Stakers. GGc coins can be redeemed for free on the Polygon network, providing a more cost-effective alternative to Ethereum.
        </div>

    </div>

    </div>
  );
};

export default About
