import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import './App.css';

import UsDebt from './pages/Tokenomics/UsDebt';
import About from './pages/About/About';
import Redeem from './pages/Redeem/Redeem';
import Stake from './pages/Stake/Stake';
import Gamble from './pages/Gamble/Gamble';
import NewsPage from './pages/News/NewsPage';

import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
      <Header />
        <Routes>
          <Route exact path="/" element={<UsDebt/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/redeem" element={<Redeem/>} />
          <Route path="/stake" element={<Stake/>} />
          <Route path="/gamble" element={<Gamble/>} />
          <Route path="/news" element={<NewsPage/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
