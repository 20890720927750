import React, { useState, useEffect } from 'react';

import './UsDebt.css';
import Gary from './gary.png';

const UsDebt = () => {
  const [usDebt, setUsDebt] = useState(0);

  useEffect(() => {
    const fetchUsDebtData = async () => {
      try {
        const response = await fetch('/us_debt.json');
        const data = await response.json();
        setUsDebt(data.usDebt);
      } catch (error) {
        console.error('Error fetching us_debt.json:', error);
      }
    };

    fetchUsDebtData();

    const interval = setInterval(() => {
      const randomDecimal = Math.floor(Math.random() * 100);
      setUsDebt((prevDebt) => prevDebt + 2500 + randomDecimal);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  function redirectToExternalPage(url) {
    window.open(url, '_blank');
  }

  return (
    <div className="container">
      <h1 style={{ color: '#e91e63' }}>
        THE OFFICIAL GARY GENSLER TRIBUTE COIN
      </h1>
      <div className="counter-container">
        <div className="counter-box">
          <h2>US National Debt</h2>
          <p style={{ marginTop: '1em' }}>${usDebt.toLocaleString()}</p>
          <div style={{ textAlign: 'center' }}>
            US INFLATION MAKES YOU POORER
          </div>
        </div>
        <div className="counter-box">
          <h2 style={{ backgroundColor: 'green' }}>GGc Circulating Supply</h2>
          <p style={{ marginTop: '1em', borderColor: 'green' }}>
            {usDebt.toLocaleString()}
          </p>
          <div style={{ textAlign: 'center' }}>
            GGC INFLATION IS SPREAD AMONGST STAKERS
          </div>
        </div>
        <img src={Gary} alt="Gary" className="bouncing-image" />
      </div>

      <div className="buttons-container">
        <button
          disabled
          className="trade-button"
          onClick={() =>
            redirectToExternalPage(
              'https://quickswap.exchange/#/swap?swapIndex=0&currency0=0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359&currency1=0x8e0166D75262214854A9D46b27126617eF67d6C3'
            )
          }
        >
          POLYGON
        </button>
        <button
          className="redeem-button"
          onClick={() =>
            redirectToExternalPage(
              'https://app.bounce.finance/auction/fixed-price/19102'
            )
          }
        >
          REDEEM
        </button>
      </div>

      <div className="distribution-container">
        <h1>DISTRIBUTION</h1>
        <p>
          <span className="total-supply">TOTAL SUPPLY:</span> ~{' '}
          {usDebt.toLocaleString()} (more or less)
        </p>
        <p>
          <span className="initial-sale">INITIAL SALE:</span> 9 trillion
        </p>
        <p>
          <span className="stake-pool">STAKE POOL:</span> 22 trillion + new
          inflation
        </p>
        <p>
          <span className="gamble-pool">GAMBLE POOL:</span> 5 trillion{' '}
        </p>
      </div>
    </div>
  );
};

export default UsDebt;
